import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import React, { useState } from 'react'
import { StoreProps, StoreSelectorProps } from '../props/StoreProps'

const StoreSelector: React.FC<StoreSelectorProps> = (props) => {
  const storeList: StoreProps[] = [...props.storeList]
  const selectAll: StoreProps = { storeId: 'all', storeName: '全店舗' }

  // デフォルト全選択
  const [selectedList, setSelectedList] = useState<StoreProps[]>([...storeList])

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[]
    const selectAllSelected = value.includes('all')

    if (selectAllSelected) {
      // 全選択または全解除
      setSelectedList(
        selectedList.length === storeList.length ? [] : [...storeList]
      )
    } else {
      // 特定の店舗が選択された場合
      const updatedList = storeList.filter((store) =>
        value.includes(store.storeId)
      )
      setSelectedList(updatedList)
    }
  }

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      paddingX={1.5} // 必要に応じてパディングを調整
    >
      <Select
        multiple
        value={selectedList.map((store) => store.storeId)}
        onChange={handleChange}
        onClose={() => {
          props.onSelectionChange(selectedList.map((store) => store.storeId))
        }}
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return '全店舗'
          }
          return selected.length === storeList.length
            ? '全店舗'
            : `${selected.length}店舗選択`
        }}
        size="small"
        sx={{
          width: '200px',
          '& .MuiOutlinedInput-root': { maxHeight: '40px' },
        }}
        MenuProps={{
          autoFocus: false,
        }}
      >
        {/* 全店舗選択オプション */}
        <MenuItem value="all">
          <Checkbox checked={selectedList.length === storeList.length} />
          <ListItemText primary="全店舗" />
        </MenuItem>

        {/* 各店舗選択オプション */}
        {storeList.map((store) => (
          <MenuItem key={store.storeId} value={store.storeId}>
            <Checkbox
              checked={selectedList.some(
                (selected) => selected.storeId === store.storeId
              )}
            />
            <ListItemText primary={store.storeName} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default StoreSelector
