import { Box, Button, Modal, Typography } from '@mui/material'
import React from 'react'

interface PopupProps {
  isPopupOpen: boolean // ポップアップを表示するかどうかのフラグ
  onClose: () => void // ポップアップを閉じるための関数
}

const Popup: React.FC<PopupProps> = ({ isPopupOpen, onClose }) => {
  return (
    <Modal
      open={isPopupOpen}
      onClose={onClose}
      aria-labelledby="popup-title"
      aria-describedby="popup-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          border: 'none', // 枠線を消す
          outline: 'none', // フォーカス時のアウトラインも消す
        }}
      >
        <Typography id="popup-title" variant="h6" component="h2">
          ご契約ありがとうございます。
        </Typography>
        <Typography id="popup-description" sx={{ mt: 2 }}>
          初回起動時はデータ連携の処理中を行う都合上、正しくデータが反映されるまで、
          お時間を要することがございます。あらかじめご了承ください。
        </Typography>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          閉じる
        </Button>
      </Box>
    </Modal>
  )
}

export default Popup
