import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { redirectToSmaregiAuth } from '../../api/SmaregiAuth'
import { useCookieExistsContext } from '../../contexts/CookieExistsContext'
import { useUserInfoContext } from '../../contexts/UserInfoContext'
import { LoginResponse } from '../../props/ResponseProps'

const LoginScreen: React.FC = () => {
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate() // useHistoryではなくuseNavigateを使用

  const { cookieExists } = useCookieExistsContext()
  const { setUserInfo } = useUserInfoContext()

  const isDebugMode = process.env.NODE_ENV === 'development' // 環境を切り替える条件

  const apikey = isDebugMode
    ? process.env.REACT_APP_API_KEY_DEBUG
    : process.env.REACT_APP_API_KEY

  const apiEndpoint = isDebugMode
    ? process.env.REACT_APP_API_GATEWAY_ENDPOINT_DEBUG
    : process.env.REACT_APP_API_GATEWAY_ENDPOINT

  // 認証コードの取得
  useEffect(() => {
    // console.log('cookieExists:', cookieExists)
    let authorizationCode: string | null = ''
    if (!cookieExists) {
      // window.location.searchを使用してURLパラメータを取得
      const params = new URLSearchParams(window.location.search)
      authorizationCode = params.get('code') // 認可コード (authorizationCode)

      // authorizationCodeが存在しない場合、エラー処理
      if (!authorizationCode) {
        setError('認可コードが見つかりません。')
        setLoading(false)
        return
      }
    }

    // API Gatewayに認可コードを送信して処理
    const callAPIGateway = async () => {
      try {
        // API GatewayのエンドポイントURL
        const apiGatewayUrl = `${apiEndpoint}/get-userinfo`
        // リクエストボディ (JSONデータ)
        const requestData = {
          authorizationCode: authorizationCode, // 認可コードを指定
        }

        // POSTリクエストを送信
        const response = await axios.post(apiGatewayUrl, requestData, {
          headers: {
            'Content-Type': 'application/json', // JSONデータを送信するためのヘッダー
            'x-api-key': apikey, // APIキーを指定
          },
          withCredentials: true,
        })

        if (response.status === 200) {
          // レスポンスが成功した場合、次のページへ遷移
          console.log('API呼び出し成功:', response.data)
          const responseData: LoginResponse = response.data

          setUserInfo({
            contractId: responseData.contractId,
            userId: responseData.userId,
            categoryList: responseData.categoryList,
            storeList: responseData.storeList,
            firstLogin: responseData.firstLogin,
          })

          // データが存在する場合
          // 　-> ダッシュボード画面へ
          navigate('/home', {
            state: { contractId: responseData.contractId },
          }) // useNavigateを使用して遷移
        } else {
          setError(`API Gatewayエラー: ${response.statusText}`)
        }
      } catch (error) {
        console.error('API Gateway呼び出しエラー:', error)
        if (axios.isAxiosError(error)) {
          // トークン認証エラー
          if (error.response?.status === 401) {
            setError('認証に失敗しました。')
            // スマレジログインへ
            redirectToSmaregiAuth()
          } else {
            setError('API Gateway呼び出しに失敗しました。')
          }
        } else {
          setError('API Gateway呼び出しに失敗しました。')
        }
      } finally {
        setLoading(false)
      }
    }

    // API Gateway呼び出しを実行
    callAPIGateway()
  }, [apiEndpoint])

  // ローディング中の表示
  if (loading) {
    return <div>認証処理中...</div>
  }

  // エラーが発生した場合の表示
  if (error) {
    return <div>エラー: {error}</div>
  }

  return <div>認証完了。ダッシュボードにリダイレクトしています...</div>
}
export default LoginScreen
