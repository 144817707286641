'use client'

import { createContext, ReactNode, useContext, useState } from 'react'
import { UserInfoProps } from '../props/UserInfo'

// Contextの型
interface UserInfoContextType {
  userInfo: UserInfoProps | null
  setUserInfo: (userInfo: UserInfoProps) => void
}

// デフォルト値の設定
const UserInfoContext = createContext<UserInfoContextType>({
  userInfo: null,
  setUserInfo: () => {},
})

// UserProviderを作成
export const UserInfoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [userInfo, setUserInfo] = useState<UserInfoProps | null>(null)
  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserInfoContext.Provider>
  )
}

// useUserContextフックを作成して簡単にContextにアクセスできるようにする
export const useUserInfoContext = () => useContext(UserInfoContext)
