import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { CookieExistsProvider } from './contexts/CookieExistsContext'
import { MenuToggleProvider } from './contexts/MenuToggleContext'
import { UserInfoProvider } from './contexts/UserInfoContext'
import themes from './theme/themes'
// import './style/style.css'

// 開発環境以外で console.log() を抑止
// import.meta.env.MODE !== "dev" && (
//   console.log = () => {},
//   console.debug = () => {},
//   console.table = () => {}
// );

ReactDOM.render(
  <ThemeProvider theme={themes.themeColor}>
    <CookieExistsProvider>
      <MenuToggleProvider>
        <UserInfoProvider>
          <React.StrictMode>
            <CssBaseline />
            <App />
          </React.StrictMode>
        </UserInfoProvider>
      </MenuToggleProvider>
    </CookieExistsProvider>
  </ThemeProvider>,
  document.getElementById('root')
)
