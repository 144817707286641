import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CommonContents from '../../components/CommonContents'
import { CategoryProps } from '../../props/CategoryProps'
import {
  CategorySalesContentProps,
  CategorySalesDataProps,
  CategorySalesListProps,
  CategorySalesProps,
} from '../../props/CategorySalesProps'
import CategorySalesChart from './CategorySalesChart'

const CategorySalesContent: React.FC<CategorySalesContentProps> = (props) => {
  // 選択した大分類
  const [selectedCategory, setSelectedCategory] = useState<string>(
    props.categoryList[0].categoryId
  )

  const [category2List, setCategory2List] = useState<CategorySalesListProps>({
    previous: [],
    current: [],
  })

  // 大分類一覧
  const ctgry1List: CategoryProps[] = []
  ctgry1List.push(
    ...props.categoryList.filter((category) => category.level === 1)
  )

  /*
   * １階層目のカテゴリを選択したときの処理
   * selectedCategory1のステートを監視
   */
  useEffect(() => {
    const newPreList: CategorySalesDataProps[] = []
    const newCurList: CategorySalesDataProps[] = []
    if (selectedCategory !== 'noSelect') {
      // 前年
      newPreList.push(
        ...props.level2CategorySales.previous.filter(
          (salesdata) => salesdata.category.categoryId1 === selectedCategory
        )
      )
      // 当年
      newCurList.push(
        ...props.level2CategorySales.current.filter(
          (salesdata) => salesdata.category.categoryId1 === selectedCategory
        )
      )
    }
    setCategory2List({ previous: newPreList, current: newCurList })
  }, [selectedCategory, props.categoryList, props.level2CategorySales])

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start">
      {/* 大分類のチャート */}
      <CommonContents
        content={
          <CategorySalesChart categorySalesList={props.level1CategorySales} />
        }
        title="部門別売上実績"
      />
    </Box>
  )
}

const CategorySales: React.FC<CategorySalesProps> = (props) => {
  // const [categorySalesResponse, setCategorySaleResponse] =
  //   useState<CategorySalesResponseProps | null>(null)

  // const [error, setError] = useState<string | null>(null)
  // const [loading, setLoading] = useState<boolean>(true)

  // useEffect(() => {
  //   const fetchCategorySalesDataFromAPI = async () => {
  //     try {
  //       setError(null)
  //       setLoading(true)
  //       const result: CategorySalesResponseProps = await fetchCategorySalesData(
  //         {
  //           contractId: props.contractId,
  //           dtddate: props.dtddate, // 日付は任意の値に変更
  //           dateType: props.dateType, // 任意の指定
  //           storeList: props.storeList,
  //         }
  //       )
  //       console.log('部門:', result)
  //       setCategorySaleResponse(result)
  //     } catch (error) {
  //       setError('データ取得中にエラーが発生しました')
  //     } finally {
  //       setLoading(false)
  //     }
  //   }

  //   fetchCategorySalesDataFromAPI()
  // }, [props])

  const content: React.ReactNode = (() => {
    //   if (loading) {
    //     return <div>Loading...</div> // ローディング表示
    //   }

    //   if (error) {
    //     return <div>{error}</div> // エラーメッセージ表示
    //   }

    //   if (!categorySalesResponse) {
    //     return <div>No data available</div> // データがない場合の処理
    //   }

    return (
      <CategorySalesContent
        categoryList={props.categoryList}
        level1CategorySales={props.data.level1CategorySales}
        level2CategorySales={props.data.level2CategorySales}
      />
    )
  })()

  return content
}

export default CategorySales
