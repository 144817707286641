import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js'
import React from 'react'
import { Chart } from 'react-chartjs-2'
import {
  CategorySalesChartProps,
  CategorySalesDataProps,
} from '../../props/CategorySalesProps'
import { roundUpBar } from '../../utils/Utils'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
)

const CategorySalesChart: React.FC<CategorySalesChartProps> = (props) => {
  const theme = useTheme()

  const preSalesList: CategorySalesDataProps[] =
    props.categorySalesList.previous
  const curSalesList: CategorySalesDataProps[] = props.categorySalesList.current

  const labels: string[] = []
  const preChartData: number[] = []
  const curChartData: number[] = []

  for (let i = 0; i < preSalesList.length; i++) {
    labels.push(preSalesList[i].category.categoryName)
    preChartData.push(preSalesList[i].sales)
    curChartData.push(curSalesList[i].sales)
  }

  const maxValue = Math.max(...preChartData, ...curChartData)

  const data = {
    labels,
    datasets: [
      {
        type: 'bar' as const,
        label: '前年売上',
        backgroundColor: theme.palette.barChart.light,
        data: preChartData,
        yAxisID: 'y',
      },
      {
        type: 'bar' as const,
        label: '当年売上',
        backgroundColor: theme.palette.barChart.main,
        data: curChartData,
        yAxisID: 'y',
      },
    ],
  }

  const options: ChartOptions<'bar'> = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        type: 'category',
        ticks: {
          autoSkip: false, // ラベルをスキップしない
          maxRotation: 20, // ラベルを45度回転
          minRotation: 20,
        },
        // 型アサーションで型エラーを回避
        ...({
          categoryPercentage: 0.7, // カテゴリ全体の幅を調整
          barPercentage: 0.8, // 棒の幅を調整
        } as any),
      },
      y: {
        beginAtZero: true,
        max: roundUpBar(maxValue),
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
    },
  }

  return (
    <Box
      sx={{
        overflowX: 'auto', // 横スクロールを有効化
      }}
    >
      <Box
        sx={{
          height: 400,
          minWidth: `${labels.length * 50}px`, // データ数に応じて横幅を設定
        }}
      >
        {preSalesList.length > 0 || curSalesList.length > 0 ? (
          <Chart type="bar" data={data} options={options} />
        ) : (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography>登録されている部門がありません。</Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default CategorySalesChart
