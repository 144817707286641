import { Box, Toolbar } from '@mui/material'
import { format, subDays } from 'date-fns'
import React, { useState } from 'react'

import { useTheme } from '@mui/material/styles'
import CustomDatePicker from '../../components/CustomDatePicker'
import StoreSelector from '../../components/StoreSelector'
import { useUserInfoContext } from '../../contexts/UserInfoContext'
import { drawerWidth } from '../../utils/Utils'
import DaylyScreenContent from './DailyScreenContent'

const DailyScreen: React.FC = () => {
  const theme = useTheme()

  const { userInfo } = useUserInfoContext()

  const contractId = userInfo?.contractId
  const categoryList = userInfo?.categoryList
  const storeList = userInfo?.storeList

  const dateType: string = '0'
  // 日付を "YYYY-MM-DD" 形式で初期化
  const today = new Date()
  const yesterday = subDays(today, 1)
  const defaultDate = format(yesterday, 'yyyy-MM-dd')
  const [selectedDate, setSelectedDate] = useState<string>(defaultDate)
  const [selectedStore, setSelectedStore] = useState<string[]>(
    storeList != null ? storeList.map((e) => e.storeId) : []
  )
  const onDateChange = (date: string | null) => {
    if (date) {
      setSelectedDate(date)
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      <Toolbar />
      <Box
        position="fixed"
        top={0}
        left={drawerWidth}
        width={`calc(100vw - ${drawerWidth}px)`}
        sx={{
          backgroundColor: theme.palette.background.default,
          zIndex: (theme) => theme.zIndex.drawer,
          borderBottom: '1px solid ',
          borderColor: theme.palette.divider,
        }} // 上部固定
      >
        <Toolbar />
        {/* ヘッダーセクション */}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          paddingY={2}
          sx={{ backgroundColor: theme.palette.background.default }}
        >
          <Box flex={1} display="flex" alignItems="center">
            <StoreSelector
              storeList={storeList ?? []}
              onSelectionChange={(list) => {
                // リストが変わっていなければ更新しない
                if (selectedStore.length === list.length) {
                  const sortedPrelist = selectedStore.sort()
                  const sortedCurlist = list.sort()
                  const match = sortedCurlist.every(
                    (e, i) => e === sortedPrelist[i]
                  )
                  if (!match) {
                    setSelectedStore(list)
                  }
                } else {
                  setSelectedStore(list)
                }
              }}
            />
          </Box>
          <Box flex={1}>
            <CustomDatePicker
              dateType={dateType}
              selectedDate={selectedDate}
              onDateChange={onDateChange}
            />
          </Box>
          <Box flex={1} />
        </Box>
      </Box>
      <DaylyScreenContent
        contractId={contractId ?? ''}
        dtddate={selectedDate}
        dateType={dateType}
        storeList={selectedStore}
        categoryList={categoryList ?? []}
      />
    </Box>
  )
}

export default DailyScreen
